import React from 'react'
import peopleRised from '../../assets/Home/peopleRised.png'
import volunteer from '../../assets/Home/volunteer.png'
import peopleSaved from '../../assets/Home/peopleSaved.png'
import countryMember from '../../assets/Home/countryMember.png'

const OurFunFact = () => {
    return (
        <div className="lg:px-20 px-10 py-10 bg-white">
            <div className="flex items-center gap-2 mb-4">
                <p>Our Services</p>
                <hr className="w-10"></hr>
            </div>
            <div className="md:flex flex-row items-center justify-between mb-4">
                <h1 className="md:text-4xl text-2xl font-serif mb-4 ">
                    We Believe that We can Save <br />
                    More Lifes with you
                </h1>
            </div>
            <div className='grid lg:grid-cols-4 grid-cols-2 gap-4'>
                <div className='flex items-center justify-center h-auto bg-[#e5f7f5] py-10'>
                    <div className='text-center'>
                        <img src={peopleRised} alt="peopleRisedimage"
                            className='w-[80px] m-auto'
                        />
                        <h1 className='text-4xl mt-4 font-medium'>7867+</h1>
                        <p className='mt-2 text-sm'>Peaople Rised</p>
                    </div>
                </div>
                <div className='flex items-center justify-center h-auto bg-[#e5f7f5] py-10'>
                    <div className='text-center'>
                        <img src={volunteer} alt="volunteerimage"
                            className='w-[80px] m-auto'
                        />
                        <h1 className='text-4xl mt-4 font-medium'>2897+</h1>
                        <p className='mt-2 text-sm'>Volunteer</p>
                    </div>
                </div>
                <div className='flex items-center justify-center h-auto bg-[#e5f7f5] py-10'>
                    <div className='text-center'>
                        <img src={peopleSaved} alt="peopleSavedimage"
                            className='w-[80px] m-auto'
                        />
                        <h1 className='text-4xl mt-4 font-medium'>10M+</h1>
                        <p className='mt-2 text-sm'>Poor People Saved</p>
                    </div>
                </div>
                <div className='flex items-center justify-center h-auto bg-[#e5f7f5] py-10'>
                    <div className='text-center'>
                        <img src={countryMember} alt="countryMemberimage"
                            className='w-[80px] m-auto'
                        />
                        <h1 className='text-4xl mt-4 font-medium'>700+</h1>
                        <p className='mt-2 text-sm'>Country Member</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurFunFact