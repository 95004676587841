import React from 'react';
// import video from '../../assets/Home_video.mp4';
import video from '../../assets/donation_compress_video.mp4';
import mobile_video from '../../assets/mobile_donation_video.mp4';
import { useNavigate } from 'react-router-dom';

const VideoSliders = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='md:contents hidden'>
                <div className='bg-black xl:h-[500px] md:h-[300px] overflow-hidden'>
                    <video
                        className='w-full h-auto'
                        src={video}
                        title=""
                        autoPlay
                        muted
                        loop
                    ></video>
                </div>
                <div className='absolute xl:mt-[-440px] lg:mt-[-270px] md:mt-[-250px] ml-[100px] '>
                    <h1 className='xl:text-[60px] lg:text-[40px] md:text-[30px] text-white font-serif font-bold'
                        style={{ textShadow: '2px 5px 4px rgba(0, 0, 0, 0.5)' }}>
                        Helping each other <br />
                        can make world <br />
                        better
                    </h1>
                    <button
                        onClick={() => navigate('/about')}
                        className='bg-white px-4 py-1 rounded cursor-pointer mt-8'
                    >
                        What we do
                    </button>
                </div>
            </div>
            <div className='md:hidden contents'>
                <div className='h-[auto] bg-black overflow-hidden'>
                    <video
                        className='w-full h-[auto] scale-[1.3]'
                        src={mobile_video}
                        title=""
                        autoPlay
                        muted
                        loop
                    ></video>
                </div>
                <div className='absolute left-8 top-40'>
                    <h1 className='text-[30px] text-blue font-serif font-bold text-white' style={{ textShadow: '2px 5px 4px rgba(0, 0, 0, 0.5)' }}>Helping each other <br /> can make world <br /> better</h1>
                    <button
                        onClick={() => navigate('/about')}
                        className='bg-white px-4 py-1 rounded cursor-pointer mt-8'>
                        What we do
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VideoSliders
