import React, { useRef, useState } from 'react';
import upi from '../../assets/UPI_comming_soon.png'
import razorpay from '../../assets/Razorpay_logo.svg'
import Razorpay from '../../pages/Razorpay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentMethodModal = ({ onClose, formData }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const modalRef = useRef();

    const handleOptionSelect = (option) => {
        if (option === 'upi') {
            toast.error('UPI option is coming soon. Please choose another option.');
        } else {
            setSelectedOption(option);
        }
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };


    return (
        <div
            onClick={handleClickOutside}
            className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
            <ToastContainer />
            <div ref={modalRef} className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-lg font-bold mb-4">Choose your payment method</h2>
                <div className="mb-4 border rounded-lg p-2 hover:border-primaryColor">
                    <label className="flex items-center cursor-pointer ">
                        <input
                            type="radio"
                            className="form-radio h-5 w-5 text-blue-600 border"
                            onChange={() => handleOptionSelect('razorpay')}
                            checked={selectedOption === 'razorpay'}
                        />
                        <div
                            className="ml-2 h-[50px] w-[300px] bg-cover bg-center"
                        >
                            <img className='w-full h-full' src={razorpay} alt="" />
                        </div>
                    </label>
                </div>
                <div className="mb-4 border rounded-lg p-2 hover:border-primaryColor">
                    <label className="flex items-center cursor-pointer">
                        <input
                            type="radio"
                            className="form-radio  h-5 w-5 text-blue-600"
                            onChange={() => handleOptionSelect('upi')}
                            checked={selectedOption === 'upi'}
                        />
                        <div
                            className="ml-2 h-[50px] w-[300px] bg-cover bg-center"
                        >
                            <img className='w-full h-full' src={upi} alt="" />
                        </div>
                    </label>
                </div>
                <div className='flex'>
                    <button
                        onClick={onClose}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md mr-4"
                    >
                        Cancel
                    </button>
                    <Razorpay selectedOption={selectedOption} formData={formData} />
                </div>
            </div>
        </div>
    );
};

export default PaymentMethodModal;
