import React from 'react';
import image from '../../assets/Resources/ResourcesHeadingCard_bg.png';

const ResourcesHeadingCard = ({ heading }) => {
    return (
        <div>
            <div
                className='bg-gray-500 h-[200px] mt-2 flex items-center justify-center'
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <h1 className='text-white font-bold text-2xl'>{heading}</h1>
            </div>
        </div>
    )
}

export default ResourcesHeadingCard