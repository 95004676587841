import React, { useEffect, useState } from 'react'
import ResourcesHeadingCard from '../components/resources/ResourcesHeadingCard'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const BlogDetails = () => {
    const [blogs, setBlogs] = useState({})
    const params = useParams()


    useEffect(() => {
        getBlogData();
    }, [])

    const getBlogData = async () => {
        try {
            const config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/blog-post/get-blog-by-id/${params?.id}`
            };

            const response = await axios.request(config);
            console.log(response?.data)
            setBlogs(response?.data)
        } catch (error) {
            console.error(error);
            console.log(error?.response?.data?.message);
        }
    }


    return (
        <div>
            <ResourcesHeadingCard heading="SSSD" />
            <div className='lg:px-20 px-10 py-5 '>
                <h1 className='text-2xl font-bold mb-2'>{blogs?.title}</h1>
                <hr />

                <div>
                    {blogs?.image && blogs?.image[1] && (
                        <img src={blogs?.image[1]} alt="blog_Image"
                            className='w-full'
                        />
                    )}
                </div>

                <div>
                    {blogs?.description?.map((ele, index) => (
                        <p key={index}
                            className='mt-2'
                        >{ele}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogDetails
