import React, { useRef, useState } from 'react';
import emailjs from "emailjs-com";
import axios from "axios";

const InquiryModal = ({ isOpen, onClose }) => {
    const [Loading, setLoading] = useState(false)
    const form = useRef();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: value ? '' : `${name} is required` });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true)
        }
    };

    const validateForm = async () => {
        let valid = true;
        const newErrors = {};

        // Validate name
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        }

        // Validate email
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
            valid = false;
        }

        // Validate phone
        if (!formData.phone.trim()) {
            newErrors.phone = 'Phone is required';
            valid = false;
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number must be 10 digits';
            valid = false;
        }

        // Validate message
        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            valid = false;
        }

        if (valid) {
            try {
                console.log("Sending email...");
                const result = await emailjs.sendForm(
                    "saikh-mirsat",
                    "template_0w44via",
                    form.current,
                    "75y0iLRdVXUT5F4oX"
                );
                if (result?.status === 200) {
                    alert("Your message has been delivered");
                    postInquiry(formData)
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error sending form:", error);
                setLoading(false);
            }
        }
        setErrors(newErrors);
        return valid;
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const postInquiry = async (data) => {
        try {

            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/inquiry/create-inquiries`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            const response = await axios.request(config);
            console.log(response)
            if (response) {
                onClose()
            }
        } catch (error) {
            console.error(error);
            alert(error?.response?.data?.message);
        }

    }

    return (
        isOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={handleOverlayClick}>
                <div className="bg-white lg:w-[40%] md:w-[70%] w-[90%] p-8 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Inquiry Form</h2>
                    <form ref={form} onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : ''}`}
                                placeholder="Enter your name"
                            />
                            {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email ? 'border-red-500' : ''}`}
                                placeholder="Enter your email"
                            />
                            {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">Phone</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.phone ? 'border-red-500' : ''}`}
                                placeholder="Enter your phone number"
                            />
                            {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className={`resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message ? 'border-red-500' : ''}`}
                                placeholder="Enter your message"
                            />
                            {errors.message && <p className="text-red-500 text-xs italic">{errors.message}</p>}
                        </div>
                        <div className='flex gap-2 items-center'>
                            <button
                                onClick={onClose}
                                className="bg-yellow-500 hover:bg-primaryColor text-white font-semibold py-2 px-4 rounded-md"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="bg-primaryColor hover:bg-primaryHoverColor text-white font-semibold py-2 px-4 rounded-md"
                            >
                                {Loading ? "Loading..." : "Submit"}
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        )
    );
};

export default InquiryModal;
