import React from 'react';
import image from '../../assets/Home/footeryellowbg.png'

const SubscribeHere = () => {
    return (
        <div
            className='mt-4 lg:px-20 px-10  md:h-[400px] h-[500px] flex items-center'
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className='md:mt-10'>
                <h1 className='text-white font-bold text-3xl'
                    style={{ textShadow: '2px 5px 4px rgba(0, 0, 0, 0.5)' }}
                >Join as newsettler</h1>
                <p className='text-primaryTextColor mt-2 lg:text-xl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsam quas assumenda, numquam temporibus adipisci magni molestias laborum aperiam nemo doloribus pariatur libero, veniam nobis accusantium, cumque quia quis ipsa eum!
                    Debitis cupiditate magni dolorum veniam natus voluptates sunt dicta quod ex, soluta deleniti nobis illo quasi amet velit molestiae earum dolorem numquam illum eum expedita ipsum modi provident consequuntur. Cum.</p>
                {/* <div className='flex gap-4 mt-4'>
                    <input type="text" name="" id=""
                        className='px-4 py-2 md:w-[40%] w-[60%] rounded-3xl outline-none'
                        placeholder='Your email address'
                    />
                    <button className='px-4 py-2 rounded-3xl bg-primaryTextColor text-white font-medium'>
                        Subscribe
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default SubscribeHere;
