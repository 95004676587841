import './App.css';
import Allroutes from './routes/Allroutes';

function App() {
  return (
    <div className='text-primaryTextColor'>
      <Allroutes />
    </div>
  );
}

export default App;
