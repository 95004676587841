import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Layout from '../layout/Layout';
import AboutChairPersons from '../pages/AboutChairPersons';
import AboutTeamMates from '../pages/AboutTeamMates';
import AboutFundInfo from '../pages/AboutFundInfo';
import Gallery from '../pages/Gallery';
import Blog from '../pages/Blog';
import PastEvent from '../pages/PastEvent';
import UpcommingEvent from '../pages/UpcommingEvent';
import PreviousProjects from '../pages/PreviousProjects';
import Career from '../pages/Career';
import Partner from '../pages/Partner';
import Campaign from '../pages/Campaign';
import BlogDetails from '../pages/BlogDetails';
import PaymentPage from '../pages/PaymentPage';
import PaymentSuccess from '../pages/PaymentSuccess';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyAndPolicy from '../pages/PrivacyAndPolicy';
import TodayEvents from '../pages/TodayEvents';
import ImageUpload from '../pages/ImageUpload';
import EventDetails from '../pages/EventDetails';

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/about-chair-persons', component: AboutChairPersons },
    { path: '/about-our-teammates', component: AboutTeamMates },
    { path: '/about-fund-info', component: AboutFundInfo },
    { path: '/gallery', component: Gallery },
    { path: '/blog', component: Blog },
    { path: '/blog/:id', component: BlogDetails },
    { path: '/past-event', component: PastEvent },
    { path: '/upcomming-event', component: UpcommingEvent },
    { path: '/today-event', component: TodayEvents },
    { path: '/previous-projects', component: PreviousProjects },
    { path: '/career', component: Career },
    { path: '/partners', component: Partner },
    { path: '/campaign', component: Campaign },
    { path: '/payment', component: PaymentPage },
    { path: '/payment-success/:id', component: PaymentSuccess },
    { path: '/term-and-conditions', component: TermsAndConditions },
    { path: '/privacy-and-policy', component: PrivacyAndPolicy },
    { path: '/image_upload', component: ImageUpload },
    { path: '/event/:id', component: EventDetails },
];

const Allroutes = () => {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route key={index} path={route.path} element={
                    <Layout>
                        <route.component />
                    </Layout>} />
            ))}
        </Routes>
    );
}

export default Allroutes;
