import React, { useEffect, useState } from 'react'
import AboutHeadingCard from '../components/about/AboutHeadingCard'
import team_card_bg from '../assets/team_card_bg.png';
import axios from 'axios';

const AboutTeamMates = () => {
    const [teamMatesList, setTeamMatesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        teamMates();
    }, [])

    const teamMates = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://sssd-ngo.onrender.com/sssd-api/team-members/find-all-members',
        };

        axios.request(config)
            .then((response) => {
                setTeamMatesList(response?.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const bg_style = {
        backgroundImage: `url(${team_card_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
    }


    return (
        <div>
            <AboutHeadingCard heading="About our Team Mates" />
            <div className='lg:px-20 px-10 py-5'>
                <div>
                    <div className='tracking-wider text-md font-semibold text-justify'>
                        Our teammates are the backbone of our organization, a diverse group of talented individuals who bring a wealth of knowledge, skills, and perspectives to the table. Each member plays a crucial role in our collective success, contributing unique insights and expertise that drive innovation and excellence. Together, we celebrate a culture of collaboration, respect, and continuous learning, where every voice is heard, and every achievement is recognized. Our team's strength lies in our unity and our commitment to supporting one another, fostering an environment where we can grow, thrive, and tackle challenges head-on. With a shared vision and unwavering dedication, our teammates are not just colleagues but a close-knit community, striving to make a meaningful impact in our field and beyond.
                    </div>

                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-4'>
                        {isLoading ? (
                            Array.from({ length: 4 }).map((_, index) => (
                                <div key={index} className='animate-pulse shadow p-2'>
                                    <div className='rounded-t bg-gray-300 h-[300px] p-8'>
                                        <div className='bg-gray-400 h-full w-full'></div>
                                    </div>
                                    <div className='bg-white text-center text-white rounded-b p-4'>
                                        <div className='h-6 bg-gray-300 rounded mb-2'></div>
                                        <div className='h-4 bg-gray-300 rounded'></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            teamMatesList.map((data, i) => {
                                const dob = data?.dob?.split('T')[0];
                                return (
                                    <div key={i} className='shadow p-2'>
                                        <img src={data?.image} alt="teamImage" className='rounded-t' />
                                        <div className='bg-primaryColor text-center text-white rounded-b p-4' style={bg_style}>
                                            <h1 className='tracking-wide'>{data?.name}</h1>
                                            <p className='text-sm tracking-wide'>Date of Birth : {dob}</p>
                                            <p className='font-serif text-sm mt-2 tracking-wide'>{data?.role}</p>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AboutTeamMates;