import React from 'react'
import AboutHeadingCard from '../components/about/AboutHeadingCard'

const About = () => {
    return (
        <div>
            <AboutHeadingCard heading="About Us" />
            <div className='lg:px-20 px-10 py-10'>
                {/* <div className="text-lg text-gray-800 leading-relaxed tracking-wider">
                    <p className="mb-4">
                        Shri Sathya Sai Development Organization (SSDO) is a dynamic non-governmental organization committed to fostering positive change in communities since [insert founding year]. With a deep-rooted dedication to uplifting the underprivileged, our mission revolves around enhancing the well-being of marginalized groups across Odisha.
                    </p>

                    <div className='lg:grid grid-cols-2 flex flex-col-reverse gap-4'>
                        <div>
                            <p className="mb-4">
                                At SSDO, we embrace a holistic approach to social development, striving to eradicate poverty, promote social inclusion, and uphold the principles of justice and equity. Our initiatives are meticulously designed to empower tribal, rural, and urban populations, ensuring they have the resources and opportunities to thrive.
                            </p>
                            <p className="mb-4">
                                Through collaborative efforts with local communities, we facilitate participatory development processes, enabling them to identify and address their unique challenges. We provide comprehensive training and capacity-building programs to grassroots organizations and individuals, equipping them with the skills and knowledge needed to drive sustainable change.
                            </p>
                        </div>
                        <div className='h-[320px]'>
                            <img className='w-full h-full' src="https://im.hunt.in/cg/guwahati/City-Guide/NGO-IN-GUWAHATI.jpg" alt="" />
                        </div>
                    </div>


                    <p className="mb-4">
                        Furthermore, our organization is dedicated to conducting rigorous research and advocacy to amplify the voices of the marginalized and advocate for their rights. We firmly believe in the transformative power of collective action and are committed to fostering partnerships and alliances to create a more just and equitable society.
                    </p>
                    <p>
                        Join us in our journey towards social support and sustainable development, where every individual has the opportunity to realize their full potential and lead a dignified life.
                    </p>

                    <div>
                        <h1 className='mt-4 font-bold text-primaryColor underline'>Contact Information:</h1>
                        <p>
                            Feel free to reach out to us at our administrative office: <br />
                            Address: Raghunathpur, Bhubaneswar, Odisha, PIN Code: 751024 <br />
                            Mobile: +91 7008275280, +91 9437087261
                        </p>
                    </div>
                </div> */}

                <div>
                    <p className='uppercase'>Know about us</p>
                    <div className='mt-4 grid lg:grid-cols-2 grid-cols-1 '>
                        <h1 className='text-4xl font-serif'>
                            We are a non-governmental <br /> organization
                        </h1>
                        <div className='lg:mt-0 mt-4'>
                            <p className='font-bold'> Shri Sathya Sai Development Organization (SSDO) is a dynamic non-governmental organization committed to fostering positive change in communities since [insert founding year]. With a deep-rooted dedication to uplifting the underprivileged, our mission revolves around enhancing the well-being of marginalized groups across Odisha.</p>
                            <p className='lg:mt-0 mt-4'> At SSDO, we embrace a holistic approach to social development, striving to eradicate poverty, promote social inclusion, and uphold the principles of justice and equity. Our initiatives are meticulously designed to empower tribal, rural, and urban populations, ensuring they have the resources and opportunities to thrive.</p>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <img src="https://plus.unsplash.com/premium_photo-1683134557797-55ab062573b5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="donation_img" />
                    </div>

                    <div className='mt-4 grid lg:grid-cols-2 grid-cols-1 gap-4'>
                        <div>
                            <p className='uppercase font-medium text-primaryColor'>Our Mission</p>
                            <p className='font-bold text-xl w-[80%] mt-2'>We make sure to provide inclusive care for children with special needs</p>
                            <p className='mt-4'>
                                At Shri Sathya Sai Development Organization (SSDO), our mission is to ensure the provision of inclusive care for children with special needs. We are dedicated to creating a supportive environment where every child, regardless of their abilities or challenges, receives the care, attention, and resources they need to thrive. Through our various programs and initiatives, we aim to empower children with special needs, promote their well-being, and foster a sense of belonging within their communities.
                            </p>
                        </div>
                        <div>
                            <p className='uppercase font-medium text-primaryColor'>Our Vision</p>
                            <p className='font-bold text-xl w-[80%] mt-2'>Provide more inclusive care to children around the world</p>
                            <p className='mt-4'>
                                Our vision at SSDO is to provide more inclusive care to children around the world. We envision a future where every child, regardless of their background or circumstances, has access to quality care and support. Through our continuous efforts and partnerships, we strive to expand our reach and impact, advocating for the rights and well-being of all children. Together, we can create a world where every child is valued, respected, and given the opportunity to thrive.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default About
