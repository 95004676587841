
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Razorpay({ selectedOption, formData }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState(null)

  console.log('Payment-----', paymentDetail)

  const UpdateUserBooking = (options) => {
    const data = {
      name: formData?.name,
      email: formData?.email,
      address: formData?.address,
      amount: formData?.amount,
      message: formData?.message,
      Razorpay: options,
    }

    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/donator/create-donators`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios.request(config)
        .then((response) => {
          console.log('create db data------->', response?.data);
          if (response?.data) {
            // console.log(response?.data?._id)
            navigate(`/payment-success/${response?.data?._id}`)
          }
        })
        .catch((error) => {
          console.log(error);
        });

    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const inicialPayment = async () => {
    if (formData) {
      let data = {
        name: formData?.name,
        email: formData?.email,
        address: formData?.address,
        amount: formData?.amount,
      };
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/pay/create-order-id`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        const response = await axios.request(config);
        console.log(response?.data)
        setPaymentDetail(response?.data)
        return response?.data?.orderId;
      } catch (error) {
        console.error("Error during payment initiation:", error);
        return false;
      }
    }
  }

  const openRazorpayCheckout = async () => {
    setLoading(true);
    const apiKey = 'rzp_test_2jegDEUcKFFBo4';
    const order_id = await inicialPayment();

    if (order_id) {
      const options = {
        key: apiKey,
        amount: formData?.amount,
        currency: "INR",
        name: formData?.name,
        description: "Testing Payments",
        image: "",
        order_id: order_id,
        prefill: {
          name: formData?.name,
          email: formData?.email,
          contact: formData?.phone,
        },
        handler: async (response) => {
          console.log("Response from Razorpay:", response);
          const config = {
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/pay/payment-callback`,
            headers: {},
            data: response,
          };
          try {
            const paymentResponse = await axios.request(config);
            console.log("Payment Callback Response:", paymentResponse);

            if (paymentResponse?.data?.payment_status === true) {
              setLoading(true);
              UpdateUserBooking(response);
            }
          } catch (error) {
            setLoading(false);
            console.error("Error in payment callback:", error);
          }
        },
        modal: {
          ondismiss: () => {
            if (window.confirm("Are you sure, you want to close the form?")) {
              console.log("Checkout form closed by the user");
            } else {
              console.log("Complete the Payment");
            }
          },
        },
      };
      setLoading(false);
      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      alert("Unable to create order");
      setLoading(false);
    }

  }

  let razorpayScriptLoaded = false;
  useEffect(() => {

    const loadRazorpayScript = async () => {
      try {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        script.onload = () => {
          console.log("Razorpay script loaded");
          razorpayScriptLoaded = true;
        };

        document.body.appendChild(script);
      } catch (error) {
        console.error("Error loading Razorpay script:", error);
      }
    };

    loadRazorpayScript();

    return () => {
      const existingScript = document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
      );

      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  useEffect(() => {
    if (razorpayScriptLoaded) {
      openRazorpayCheckout();
    }
  }, [razorpayScriptLoaded]);


  const handleSubmit = () => {
    openRazorpayCheckout()
  }

  return (
    <div className='flex items-center justify-center'>
      <button
        className={`${selectedOption === 'razorpay'
          ? 'bg-primaryColor hover:bg-primaryHoverColor'
          : 'bg-gray-500 cursor-not-allowed'
          } text-white font-bold py-2 px-4 rounded w-[200px]`}
        disabled={selectedOption !== 'razorpay'}
        onClick={handleSubmit}
      >
        {loading ? "Loading...." : `Pay ₹${formData?.amount}`}
      </button>
    </div>
  );
}




