import React from 'react';

const PrivacyAndPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8 flex flex-col gap-2">
            <h1 className="text-2xl font-semibold mb-4">Privacy Policy</h1>
            <p>
                At Shri Sathya Sai Development Organization (SSDO), we are committed to protecting the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website.
            </p>
            <h2 className="text-lg font-semibold ">Information We Collect</h2>
            <p>
                We may collect personal information from you such as your name, email address, phone number, and address when you interact with our website, such as when you make a donation, subscribe to our newsletter, or fill out a contact form.
            </p>
            <h2 className="text-lg font-semibold ">How We Use Your Information</h2>
            <p>
                We use the information we collect to communicate with you, process donations, improve our website, and provide you with information about our organization and activities. We do not share your personal information with third parties except as necessary to provide our services or as required by law.
            </p>
            <h2 className="text-lg font-semibold ">Data Security</h2>
            <p>
                We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure and we cannot guarantee absolute security.
            </p>
            <h2 className="text-lg font-semibold ">Your Rights</h2>
            <p>
                You have the right to access, correct, or delete your personal information at any time. If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us.
            </p>
            <h2 className="text-lg font-semibold ">Changes to This Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the date of the last update will be indicated at the top of the page.
            </p>
            <p>
                By using our website, you consent to the terms of this Privacy Policy.
            </p>
        </div>
    );
}

export default PrivacyAndPolicy;
