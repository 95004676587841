import React, { useState, useRef, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LatestNews = () => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const [blogs, setBlogs] = useState([]);
    const [showScrollbar, setShowScrollbar] = useState(false);
    const [hasNextContent, setHasNextContent] = useState(false);
    const scrollContainerRef = useRef(null);
    const cardWidth = 310;
    const navigate = useNavigate();

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const { scrollWidth, clientWidth } = scrollContainer;
            setShowScrollbar(scrollWidth > clientWidth);
            setHasNextContent(scrollWidth > clientWidth + scrollLeft);
        }
    }, [scrollContainerRef, scrollLeft]);

    const handleNext = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const { scrollWidth, clientWidth } = scrollContainer;
            const maxScrollLeft = scrollWidth - clientWidth;
            const nextScrollLeft = scrollLeft + cardWidth;
            if (nextScrollLeft <= maxScrollLeft) {
                setScrollLeft(nextScrollLeft);
                scrollContainer.scrollTo({
                    left: nextScrollLeft,
                    behavior: 'smooth',
                });
            }
            setHasNextContent(scrollWidth > clientWidth + nextScrollLeft);
        }
    };

    const handlePrev = () => {
        const nextScrollLeft = scrollLeft - cardWidth;
        if (nextScrollLeft >= 0) {
            setScrollLeft(nextScrollLeft);
            scrollContainerRef.current.scrollTo({
                left: nextScrollLeft,
                behavior: 'smooth',
            });
        } else {
            setScrollLeft(0);
            scrollContainerRef.current.scrollTo({
                left: 0,
                behavior: 'smooth',
            });
        }
        setHasNextContent(true);
    };

    const getBlogData = async () => {
        try {
            const config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/blog-post/get-all-blogs`
            };

            const response = await axios.request(config);
            // console.log(response?.data)
            setBlogs(response?.data)
        } catch (error) {
            console.error(error);
            // console.log(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getBlogData();
    }, []);

    function formatDate(dateString) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        } else {
            return text;
        }
    };



    return (
        <div className="lg:px-20 px-10 pt-0 py-10 bg-white">
            <div className="flex items-center gap-2 mb-4">
                <p>Latest Blogs</p>
                <hr className="w-10"></hr>
            </div>
            <div className="md:flex flex-row items-center justify-between">
                <h1 className="md:text-4xl text-2xl font-serif mb-4 ">
                    Articel You May Read
                </h1>
                <div className="flex items-center gap-2 mr-4 md:mb-0 mb-4">
                    <button
                        onClick={handlePrev}
                        disabled={scrollLeft === 0}
                        style={{ color: scrollLeft === 0 ? 'grey' : 'black' }}
                        className={`${scrollLeft === 0 ? "bg-gray-300 text-gray-400" : "bg-[#E5F7F5]"} border p-2 rounded-full  flex items-center justify-center`}
                    >
                        <IoIosArrowBack />
                    </button>
                    <button
                        onClick={handleNext}
                        disabled={!showScrollbar || !hasNextContent}
                        className={`${!showScrollbar || !hasNextContent ? "bg-gray-300 text-gray-400" : "bg-[#E5F7F5]"} border p-2 rounded-full flex items-center justify-center`}
                    >
                        <IoIosArrowForward />
                    </button>
                </div>
            </div>
            <div
                className="flex gap-4 rounded-xl overflow-x-scroll pb-4"
                ref={scrollContainerRef}
            >
                {blogs?.map((ele) => (
                    <div key={ele?._id} className="w-[310px] flex-shrink-0 shadow-md border rounded-xl">
                        <div className="w-full h-[250px] overflow-hidden">
                            <img
                                src={ele?.image[0]}
                                alt="images"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="p-4">
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center gap-1 text-sm text-secondaryTextColor'>
                                    <FaUserAlt />
                                    <p>{ele?.author}</p>
                                </div>
                                <div className='flex items-center gap-1 text-sm text-secondaryTextColor'>
                                    <SlCalender />
                                    <p>{formatDate(ele?.dateOfPublish)}</p>
                                </div>
                            </div>
                            <div className='my-2'>
                                {truncateText(ele?.title, 35)}
                            </div>
                            <p className='text-sm tracking-wide text-secondaryTextColor'>
                                {truncateText(ele?.description[0], 80)}
                            </p>
                            <button
                                onClick={() => {
                                    setScrollLeft(0)
                                    setShowScrollbar(false)
                                    navigate(`/blog/${ele?._id}`)
                                }}
                                className='text-sm border border-primaryColor mt-4 px-4 py-2 rounded-md hover:bg-primaryColor cursor-pointer transition duration-100 ease-in-out hover:text-white'>Read More</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default LatestNews