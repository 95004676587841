import React, { useEffect, useState } from 'react';
import AboutHeadingCard from '../components/about/AboutHeadingCard';
import fund_card_bg from '../assets/fund_card_bg.png';
import axios from 'axios';

const AboutFundInfo = () => {
    const [fundHistory, setFundHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        funds();
    }, [])

    const funds = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://sssd-ngo.onrender.com/sssd-api/fund-collection-history/get-fund-history',
            headers: {},
            data: ''
        };

        axios.request(config)
            .then((response) => {
                console.log("resssss", response?.data);
                setFundHistory(response?.data);
                setIsLoading(false); // Set loading to false when data is fetched
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const bg_style = {
        backgroundImage: `url(${fund_card_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
    }

    return (
        <div>
            <AboutHeadingCard heading="About Fund Info" />
            <div className='lg:px-20 px-10 py-5'>
                <div>
                    <h1 className='text-2xl font-normal'>
                        FC Fund Received
                    </h1>
                    <div className='mt-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4'>
                        {isLoading ? (
                            Array.from({ length: 8 }).map((_, index) => (
                                <div key={index} className='text-center p-2 rounded-xl shadow-lg h-20 animate-pulse'>
                                    <div className='bg-gray-200 p-4 rounded-lg h-full tracking-wider grid grid-cols-1 gap-2'>
                                        <div className='h-4 bg-gray-500 rounded-lg'></div>
                                        <div className='h-4 bg-gray-500 rounded-lg'></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            fundHistory.map((data, i) => (
                                <div
                                    key={i}
                                    className='text-center p-2 rounded-xl shadow-lg'
                                    style={bg_style}>
                                    <div className='bg-white p-2 rounded-lg tracking-wider'>
                                        <h1 className='font-bold text-xl'>
                                            ₹ {data?.amount}
                                        </h1>
                                        <p>
                                            {data?.timeDuration}
                                        </p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutFundInfo;
