import React from 'react'
import DesktopNavbar from '../components/Navbar/DesktopNavbar';
import MobileNavbar from '../components/Navbar/MobileNavbar';

const Navbar = () => {
    return (
        <div>
            <div className='lg:contents hidden'>
                <DesktopNavbar />
            </div>
            <div className='lg:hidden contents'>
                <MobileNavbar />
            </div>
        </div>
    )
}

export default Navbar;