import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaLocationArrow } from "react-icons/fa6";
const EventDetails = () => {
    const [eventData, setEventsData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        getEventsById();
    }, []);

    const getEventsById = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/event/get-events/${params?.id}`,
        };
        axios.request(config)
            .then((response) => {
                console.log("data", response?.data);
                setEventsData(response?.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    return (
        <div className='lg:px-20 px-10 py-5'>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className='lg:flex gap-4 grid grid-cols-1'>
                    <div className='lg:w-3/4'>
                        <img
                            src={eventData?.images[0]}
                            alt="event image"
                            className='h-[400px] w-full'
                        />
                        <div className='mt-4'>
                            <h1 className='text-2xl font-medium mb-3'>{eventData?.title}</h1>
                            {
                                eventData?.descriptions?.map((ele, index) => (
                                    <p key={index} className='text-secondaryTextColor mb-3'>
                                        {ele}
                                    </p>
                                ))
                            }
                        </div>
                        {
                            eventData?.videos?.map((ele, index) => (
                                <div className='mb-4'>
                                    <iframe
                                        key={index}
                                        src={ele}
                                        title={`video-${index}`}
                                        width='100%'
                                        height='400px'
                                        allowFullScreen
                                        frameBorder='0'
                                    ></iframe>
                                </div>
                            ))
                        }
                    </div>
                    <div className='lg:w-1/4'>
                        <h1 className='text-primaryTextColor text-xl font-semibold'>Important links</h1>
                        <div className='flex items-center gap-3 mt-2'>
                            <FaLocationArrow />
                            <Link to='https://www.mygov.in/covid-19' target='_blank' className='cursor-pointer text-blue-600' >
                                COVID-19,GoI
                            </Link>
                        </div>
                        <div className='flex items-center gap-3 mt-2'>
                            <FaLocationArrow />
                            <Link to='https://www.mohfw.gov.in/' target='_blank' className='cursor-pointer text-blue-600' >
                                Ministry of Health & Family Welfare,GoI
                            </Link>
                        </div>
                        <div className='flex items-center gap-3 mt-2'>
                            <FaLocationArrow />
                            <Link to='https://www.mohfw.gov.in/' target='_blank' className='cursor-pointer text-blue-600' >
                                Department of Health,GoO
                            </Link>
                        </div>
                        <div className='flex items-center gap-3 mt-2'>
                            <FaLocationArrow />
                            <Link to='https://www.who.int/' target='_blank' className='cursor-pointer text-blue-600' >
                                World Health Organization (WHO)
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventDetails;
