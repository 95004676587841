import React from 'react'
import image from '../../assets/Home/YourSupport.png'

const YourSupport = () => {
    return (
        <div className='lg:px-20 lg:pb-20 px-10 pb-10 grid lg:grid-cols-2 grid-cols-1  items-center lg:gap-[100px] gap-[20px]'>
            <div className='flex items-center justify-center'>
                <img src={image} alt=""
                    className='lg:w-[80%] w-[100%]'
                />
            </div>
            <div className='flex items-center'>
                <div>
                    <div className='flex items-center gap-2'>
                        <p>About us</p>
                        <hr className='w-10'></hr>
                    </div>
                    <div className='text-[30px] font-serif'>
                        Your Support is Really <br />
                        Powerful.
                    </div>
                    <p className='text-sm text-secondaryTextColor lg:w-[90%]'>
                        The secret to happiness lies in helping others. Never
                        underestimate the difference YOU can make in the
                        lives of the poor, the abused and the helpless.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt voluptates ullam tenetur culpa sit veniam excepturi ducimus ad hic laudantium atque placeat, eius optio qui asperiores. In animi est quaerat?
                    </p>
                    <button className='bg-primaryColor text-white px-4 py-1 rounded mt-4'>Read More</button>
                </div>
            </div>
        </div>
    )
}

export default YourSupport