import React from 'react'
import iso from '../../assets/CompaniesLogo/iso.png'
import raple from '../../assets/CompaniesLogo/raple.png'
import united from '../../assets/CompaniesLogo/unitedkingdom.png'
import egac from '../../assets/CompaniesLogo/egac.png'
import iaf from '../../assets/CompaniesLogo/iaf.png'
import smrc from '../../assets/CompaniesLogo/smrc.png'

const CompanisLogo = () => {
  return (
    <div className='lg:px-20 px-2 py-10'>
    <div className='md:flex grid grid-cols-3 items-center justify-around'>
        <div className='h-[100%] flex items-center justify-center'>
            <img
                src={raple}
                alt="companies Logo images"
                className='w-[80px]'
            />
        </div>
        <div className='h-[100%] flex items-center justify-center'>
            <img
                src={united}
                alt="companies Logo images"
                className='w-[100px]'
            />
        </div>
        <div className='h-[100%] flex items-center justify-center'>
            <img
                src={egac}
                alt="companies Logo images"
                className='w-[60px]'
            />
        </div>
        <div className='h-[100%] flex items-center justify-center'>
            <img
                src={iaf}
                alt="companies Logo images"
                className='w-[60px]'
            />
        </div>
        <div className='h-[100%] flex items-center justify-center'>
            <img
                src={iso}
                alt="companies Logo images"
                className='w-[100px]'
            />
        </div>
        <div className='h-[100%] flex items-center justify-center'>
            <img
                src={smrc}
                alt="companies Logo images"
                className='w-[100px]'
            />
        </div>
    </div>
</div>
  )
}

export default CompanisLogo