import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProjectHeadingCard from '../components/project/ProjectHeadingCard';

const PreviousProjects = () => {
    const [selectedId, setSelectedId] = useState();
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        projectsList();
    }, [])

    const projectsList = () => {
        let data = '';

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/projects/get-all-projects`,
            headers: {},
            data: data
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                // console.log(response.data);
                setIsLoading(false);
                setProjects(response?.data);
                setSelectedId(response?.data[0]?._id)
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }

    const handleClick = (id) => {
        setSelectedId(id);
    }

    return (
        <div>
            <ProjectHeadingCard heading="Previous Projects" />
            <div className='lg:px-20 px-10 py-5 '>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                    <div className='flex flex-col gap-4'>
                        <div className='text-center p-3 bg-slate-600 text-white font-bold text-xl tracking-wider'>
                            Projects List
                        </div>
                        {
                            isLoading
                                ?
                                [1, 2, 3, 4].map((ele, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="border p-4"
                                        >
                                            <div className=" bg-skeletonLoaderColor h-6 animate-pulse w-full cursor-pointer">&nbsp;</div>
                                        </div>
                                    )
                                }
                                )
                                :
                                projects?.map((ele) => {
                                    return (
                                        <div
                                            key={ele._id}
                                            onClick={() => handleClick(ele._id)}
                                            className={`border p-4 cursor-pointer hover:bg-primaryColor hover:text-white transition duration-200 ease-in-out ${selectedId === ele._id ? 'bg-primaryColor text-white' : ''}`}
                                        >
                                            {ele?.projectName}
                                        </div>
                                    )
                                }
                                )
                        }
                    </div>

                    <div className='border-2 border-primaryColor max-h-full'>
                        <div className='text-center p-3 bg-slate-600 text-white font-bold text-xl tracking-wider'>
                            Projects Details
                        </div>

                        {
                            isLoading
                                ?
                                [1, 2, 3, 4, 5].map((i) => {
                                    return (
                                        <div key={i} className='p-4'>
                                            <div className=" bg-skeletonLoaderColor mt- h-6 animate-pulse w-full cursor-pointer">&nbsp;</div>
                                        </div>
                                    )
                                })
                                :
                                selectedId !== null && (
                                    <div className='p-4 tracking-wider overflow-y-scroll max-h-[85%]'>
                                        <div>
                                            <span className='font-bold text-primaryColor'>
                                                Name
                                            </span> : {projects?.find(item => item._id === selectedId)?.projectName}
                                        </div>

                                        <div>
                                            <span className='font-bold text-primaryColor'>
                                                Duration
                                            </span> : {projects?.find(item => item._id === selectedId)?.timePeriod}
                                        </div>

                                        <div>
                                            <span className='font-bold text-primaryColor'>
                                                Location
                                            </span> : {projects?.find(item => item._id === selectedId)?.location}
                                        </div>

                                        <div>
                                            <span className='font-bold text-primaryColor text-justify'>
                                                Description
                                            </span> : {projects?.find(item => item._id === selectedId)?.description}
                                        </div>
                                    </div>
                                )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviousProjects;







// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import ProjectHeadingCard from '../components/project/ProjectHeadingCard';

// const data = [
//     {
//         name: "Sustainable Livelihoods",
//         description: ["Goal/ Objective: Enable the poor tribal households to ensure their livelihoods and food security through efficient, equitable, self-managed and optimum use of the natural resources; off-farm/ non-farm enterprise development; and access to rights and entitlements.", "Goal/ Objective: Enable the poor tribal households to ensure their livelihoods and food security through efficient, equitable, self-managed and optimum use of the natural resources; off-farm/ non-farm enterprise development; and access to rights and entitlements.", "Goal/ Objective: Enable the poor tribal households to ensure their livelihoods and food security through efficient, equitable, self-managed and optimum use of the natural resources; off-farm/ non-farm enterprise development; and access to rights and entitlements."],
//         id: 1
//     },
//     {
//         name: "Inclusive Governance",
//         description: ["hello"],
//         id: 2
//     },
//     {
//         name: "Skill Development",
//         description: ["hi"],
//         id: 3
//     },
// ]

// const PreviousProjects = () => {
//     const [selectedId, setSelectedId] = useState(data[0].id);
//     const [projects, setProjects] = useState([]);

//     useEffect(() => {
//         projectsList();
//     }, [])

//     const projectsList = () => {
//         let data = '';

//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: 'https://sssd-ngo.onrender.com/sssd-api/projects/get-all-projects',
//             headers: {},
//             data: data
//         };

//         axios.request(config)
//             .then((response) => {
//                 // console.log(response.data);
//                 setProjects(response?.data)
//             })
//             .catch((error) => {
//                 console.log(error);
//             });

//     }

//     const handleClick = (id) => {
//         setSelectedId(id);
//     }

//     return (
//         <div>
//             <ProjectHeadingCard heading="Our Projects" />

//             <div className='lg:px-20 px-10 py-5'>
//                 <div className='grid grid-cols-2 gap-4'>
//                     <div className='flex flex-col gap-4'>
//                         {
//                             projects
//                             &&
//                             projects?.map((ele, i) => {
//                                 return (
//                                     <div
//                                         key={ele.id}
//                                         onClick={() => handleClick(ele?.id)}
//                                         className={`border p-4 cursor-pointer hover:bg-primaryColor hover:text-white transition duration-200 ease-in-out ${selectedId === ele.id ? 'bg-primaryColor text-white' : ''}`}
//                                     >
//                                         {ele?.projectName}
//                                     </div>
//                                 )
//                             }
//                             )
//                         }
//                     </div>

//                     <div className='border-2 border-primaryColor h-[205px] overflow-y-scroll'>
//                         {selectedId !== null && (
//                             <div className='p-4 overflow-y-scroll'>
//                                 description
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default PreviousProjects;

