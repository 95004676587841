import React, { useState, useRef } from 'react';
import logo from '../../assets/logo.svg'
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { FaCaretDown } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";
import { LiaDonateSolid } from "react-icons/lia";
import { Link, useNavigate } from 'react-router-dom';
import InquiryModal from '../query/InquiryModal';

const MobileNavbar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const sidebarRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate()

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setIsSidebarOpen(false)
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    const handleItemClicked = (name) => {
        setActiveItem(activeItem === name ? null : name);
    };

    return (
        <div className="bg-white">
            <InquiryModal isOpen={isModalOpen} onClose={toggleModal} />
            <div className="container mx-auto flex items-center justify-between py-2 md:px-20 px-2">
                <div className="text-white">
                    <img src={logo} alt="" className='w-[30px]' />
                </div>

                <div className="text-black cursor-pointer" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
            </div>

            <div className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-opacity ${isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={closeSidebar}>
                {/* Sidebar content */}
                <div
                    ref={sidebarRef}
                    className="fixed top-0 right-0 h-full bg-primaryColor w-64 transform transition-transform duration-300 ease-in-out p-2"
                >
                    <button
                        className=''
                        onClick={() => setIsSidebarOpen(false)}
                    >
                        <IoClose color='white' size={30} />
                    </button>
                    <div className='text-white flex flex-col gap-2'>
                        <Link to='/'
                            onClick={() => setIsSidebarOpen(false)}
                            className='border py-1 px-2 rounded'
                        >
                            Home
                        </Link>
                        <div
                            onClick={() => {
                                setIsSidebarOpen(false)
                                handleItemClicked('About')
                            }}
                            className='border py-1 px-2 rounded flex items-center gap-1'>
                            About
                            <FaCaretDown />
                        </div>
                        {
                            activeItem === 'About' &&
                            <div className='ml-10 flex flex-col gap-2  transform transition-transform duration-300 ease-in-out'>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/about"
                                    className='border py-1 px-2 rounded'
                                >
                                    About Us
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/about-chair-persons"
                                    className='border py-1 px-2 rounded'
                                >
                                    Chair Persons
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/about-our-teammates"
                                    className='border py-1 px-2 rounded'
                                >
                                    Teammates
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/about-fund-info"
                                    className='border py-1 px-2 rounded'
                                >
                                    Fund Info
                                </Link>
                            </div>
                        }

                        {/* <div onClick={() => handleItemClicked('Programme')} className='border py-1 px-2 rounded flex items-center gap-1'>
                            Programme
                            <FaCaretDown />
                        </div>
                        {
                            activeItem === 'Programme' &&
                            <div className='ml-10 flex flex-col gap-2   transition duration-300 ease-in-out'>
                                <div className='border py-1 px-2 rounded'>
                                    Resources
                                </div>
                                <div className='border py-1 px-2 rounded'>
                                    Events
                                </div>
                                <div className='border py-1 px-2 rounded'>
                                    Get Involved
                                </div>
                            </div>
                        } */}

                        <div onClick={() => handleItemClicked('Resources')} className='border py-1 px-2 rounded flex items-center gap-1'>
                            Resources
                            <FaCaretDown />
                        </div>
                        {
                            activeItem === 'Resources' &&
                            <div className='ml-10 flex flex-col gap-2  transform transition-transform duration-300 ease-in-out'>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/gallery"
                                    className='border py-1 px-2 rounded'>
                                    Gallery
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/blog"
                                    className='border py-1 px-2 rounded'>
                                    Blog
                                </Link>
                            </div>
                        }

                        <div
                            onClick={() => handleItemClicked('Events')}
                            className='border py-1 px-2 rounded flex items-center gap-1'
                        >
                            Events
                            <FaCaretDown />
                        </div>
                        {
                            activeItem === 'Events' &&
                            <div className='ml-10 flex flex-col gap-2  transform transition-transform duration-300 ease-in-out'>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/past-event" className='border py-1 px-2 rounded'>
                                    Past
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/upcomming-event" className='border py-1 px-2 rounded'>
                                    Upcoming
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/today-event" className='border py-1 px-2 rounded'>
                                    Today's
                                </Link>
                            </div>
                        }

                        <div onClick={() => handleItemClicked('Get Involved')} className='border py-1 px-2 rounded flex items-center gap-1'>
                            Get Involved
                            <FaCaretDown />
                        </div>
                        {
                            activeItem === 'Get Involved' &&
                            <div className='ml-10 flex flex-col gap-2  transform transition-transform duration-300 ease-in-out'>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/partners" className='border py-1 px-2 rounded'>
                                    Partners
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/career" className='border py-1 px-2 rounded'>
                                    Career
                                </Link>
                                <Link
                                    onClick={() => setIsSidebarOpen(false)}
                                    to="/campaign" className='border py-1 px-2 rounded'>
                                    Campaign
                                </Link>
                            </div>
                        }

                        <Link onClick={() => setIsSidebarOpen(false)} to="/previous-projects" className='border py-1 px-2 rounded'>
                            Projects
                        </Link>
                    </div>

                    <div className='mt-2 w-[100%] flex gap-2'>
                        <button
                            onClick={toggleModal}
                            className='px-4 py-1 rounded border border-white text-white flex items-center gap-2 w-[100%]'>
                            Inquiry
                            <BsQuestionCircle />
                        </button>
                        <button
                            onClick={() => {
                                setIsSidebarOpen(false)
                                navigate('/payment')
                            }}
                            className='px-4 py-1 rounded border bg-white text-primaryColor flex items-center gap-2 w-[100%]'>
                            Donate
                            <LiaDonateSolid size={18} />
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MobileNavbar;
