import React, { useState } from 'react';
import GetInvolvedHeadingCard from '../components/getInvolved/GetInvolvedHeadingCard';
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";

const Partner = () => {
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleDropdownToggle = (partner) => {
        setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === partner ? null : partner));
    };

    const renderDropdownContent = (partner) => {
        switch (partner) {
            case 'Government':
                return (
                    <div className='p-3'>
                        <div className='grid grid-cols-3 gap-4 h-[250px] overflow-y-scroll border'>
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OTELP.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2022/06/MANAGE-160x160.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OSDA.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/odisha.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/nkc.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/brlf.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/rgniyd.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                        </div>
                    </div>
                );
            case 'International Organisations':
                return (
                    <div className='p-3'>
                        <div className='grid grid-cols-3 gap-4 h-[250px] overflow-y-scroll border'>
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OTELP.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2022/06/MANAGE-160x160.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OSDA.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/odisha.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/nkc.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/brlf.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/rgniyd.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                        </div>
                    </div>
                );
            case 'National NGOs':
                return (
                    <div className='p-3'>
                        <div className='grid grid-cols-3 gap-4 h-[250px] overflow-y-scroll border'>
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OTELP.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2022/06/MANAGE-160x160.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OSDA.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/odisha.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/nkc.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/brlf.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/rgniyd.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                        </div>
                    </div>
                );
            case 'Corporate':
                return (
                    <div className='p-3'>
                        <div className='grid grid-cols-3 gap-4 h-[250px] overflow-y-scroll border'>
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OTELP.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2022/06/MANAGE-160x160.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/OSDA.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/odisha.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/nkc.png" alt="partnerImage"
                                className='border w-full h-full'
                            />

                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/brlf.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                            <img
                                src="https://www.cysd.org/wp-content/uploads/2018/07/rgniyd.png" alt="partnerImage"
                                className='border w-full h-full'
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <GetInvolvedHeadingCard heading="Partners" />
            <div className='lg:px-20 px-10 py-5 tracking-wider'>
                <p>
                    At Shri Sathya Sai Development Organization (SSSD), we firmly acknowledge that addressing complex societal issues requires a concerted effort involving various stakeholders including the government, civil society, individuals, and corporations.
                </p>
                <p className='mt-4'>
                    To amplify the impact of our initiatives and introduce innovative pilot projects, we actively seek collaborations and partnerships. We believe in fostering mutually beneficial relationships with all our stakeholders. At SSSD, we value the exchange of knowledge and experiences with our collaborators.
                </p>
                <p className='mt-4'>
                    If you share our commitment to improving the lives of the underprivileged and are passionate about driving positive change, we invite you to reach out to us at sssd@example.org. Let's work together to create a brighter future for those in need.
                </p>
                <div>
                    <h1 className='mt-4 font-medium text-2xl font-serif'>Our Partners</h1>
                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                        {['Government', 'International Organisations', 'National NGOs', 'Corporate'].map((partner, index) => (
                            <div
                                key={index}
                                className={`relative  transitio duration-200 ease-in-out ${openDropdown === partner ? 'bg-primaryColor hover:bg-none hover:bg-red-500 text-white' : 'hover:bg-gray-300'}`}
                            >
                                <div
                                    onClick={() => handleDropdownToggle(partner)}
                                    className='cursor-pointer border border-gray-200 py-2 px-4 flex items-center justify-between'
                                >
                                    <p>{partner}</p>
                                    <p>
                                        {openDropdown === partner ? <CiSquareMinus size={20} /> : <CiSquarePlus size={20} />}
                                    </p>
                                </div>
                                {openDropdown === partner && (
                                    <div className='absolute left-0 mt-2 text-black bg-white shadow-md w-full rounded-md z-10'>
                                        {renderDropdownContent(partner)}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Partner;
