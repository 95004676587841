import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { FiArrowUp } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {
    const [showGoToTop, setShowGoToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setShowGoToTop(true);
            } else {
                setShowGoToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <main className='bg-[#F7FFFF] relative tracking-wider'>
            <Navbar />
            {children}
            <Footer />

            <button
                className={`p-2 bg-primaryColor rounded-full bottom-4 md:left-[95%] left-[80%] sticky cursor-pointer transition-opacity duration-300 ${showGoToTop ? 'opacity-100' : 'opacity-0'}`}
                onClick={scrollToTop}
                style={{ opacity: showGoToTop ? 1 : 0 }}>
                <FiArrowUp size={25} />
            </button>

            <Link to='https://web.whatsapp.com/'>
                <button className='p-2 bg-green-500 rounded-full bottom-4 left-0 sticky cursor-pointer' onClick={scrollToTop}>
                    <FaWhatsapp size={25} />
                </button>
            </Link>
        </main>
    );
};

export default Layout;
