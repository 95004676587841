import React from 'react';
import YourSupport from '../components/Homes/YourSupport';
import OurServices from '../components/Homes/OurServices';
import WelcomeToCharity from '../components/Homes/WelcomeToCharity'
import OurFunFact from '../components/Homes/OurFunFact';
import LatestNews from '../components/Homes/LatestNews';
import OurTestimonials from '../components/Homes/OurTestimonials';
import SubscribeHere from '../components/Homes/SubscribeHere';
import VideoSliders from '../components/Homes/VideoSliders';
import CompanisLogo from '../components/Homes/CompanisLogo';

const Homes = () => {
    return (
        <React.Fragment>
            <VideoSliders />
            <CompanisLogo />
            <YourSupport />
            <OurServices />
            <WelcomeToCharity />
            <OurFunFact />
            <LatestNews />
            <OurTestimonials />
            <SubscribeHere />
        </React.Fragment>
    )
}

export default Homes