import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/logo.svg'
import { BsQuestionCircle } from "react-icons/bs";
import { LiaDonateSolid } from "react-icons/lia";
import { FaCaretDown } from "react-icons/fa";
import './DesktopNavbar.css';
import InquiryModal from '../query/InquiryModal';

const DesktopNavbar = () => {
    const [activeItem, setActiveItem] = useState(null);
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleMouseEnter = (index) => {
        setActiveItem(index);
    };

    const handleMouseLeave = () => {
        setActiveItem(null);
    };

    return (
        <div className=''>
            <InquiryModal isOpen={isModalOpen} onClose={toggleModal} />
            <div className="marquee-container flex justify-center items-center bg-primaryColor text-primaryBrown text-white px-10 py-2 text-sm font-semibold tracking-wider overflow-hidden">
                <div className="marquee cursor-pointer tracking-wider">
                    Shri Sathya Sai Development Organization (SSDO) is a dynamic non-governmental organization committed to fostering positive change in communities. With a deep-rooted dedication to uplifting the underprivileged, our mission revolves around enhancing the well-being of marginalized groups across Odisha.
                </div>
            </div>

            <div className='px-20 py-2 flex items-center justify-between'>
                <img className='w-[60px]' src={logo} alt="logo" />
                <div className='flex items-center'>
                    <div className='flex gap-4'>
                        <button
                            onClick={toggleModal}
                            className='px-4 py-2 rounded border border-primaryColor  hover:bg-primaryColor hover:text-white transition duration-200 ease-in-out flex items-center gap-2'>
                            Inquiry
                            <BsQuestionCircle />
                        </button>
                        <button
                            onClick={() => navigate('/payment')}
                            className='px-4 py-2 rounded border border-primaryColor bg-primaryColor text-white hover:bg-white hover:text-black transition duration-200 ease-in-out flex items-center gap-2'>
                            Donate
                            <LiaDonateSolid size={18} />
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-between gap-2 bg-primaryColor text-white font-medium px-20 m-auto'>
                <div >
                    <div
                        className=' py-2 px-4 flex gap-2 items-center cursor-pointer'
                        onClick={() => navigate('/')}
                    >
                        Home
                    </div>
                </div>

                <div className='relative' onMouseEnter={() => handleMouseEnter('About')} onMouseLeave={handleMouseLeave}>
                    <div className=' py-2 px-4 flex gap-1 items-center cursor-pointer'>
                        About
                        <FaCaretDown size={16} />
                    </div>
                    {activeItem === 'About' && (
                        <div className='absolute top-full mt-[0px] z-50 bg-white text-black shadow-lg w-[200px] rounded-b-[20px] overflow-hidden'>
                            <Link to="/about" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>About Us</Link>
                            <Link to="/about-chair-persons" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Chair Persons</Link>
                            <Link to="/about-our-teammates" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Team Mates</Link>
                            <Link to="/about-fund-info" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Fund Info</Link>
                        </div>
                    )}
                </div>

                {/* <div className='relative' onMouseEnter={() => handleMouseEnter('Programme')} onMouseLeave={handleMouseLeave}>
                    <div className=' py-2 px-4 flex gap-1 items-center cursor-pointer'>
                        Programme
                        <FaCaretDown size={16} />
                    </div>
                    {activeItem === 'Programme' && (
                        <div className='absolute top-full mt-[0px] z-50 bg-white text-black shadow-lg w-[200px] rounded-b-[20px] overflow-hidden'>
                            <Link to="/dropdown7" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Dropdown 7</Link>
                            <Link to="/dropdown8" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Dropdown 8</Link>
                            <Link to="/dropdown9" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Dropdown 9</Link>
                        </div>
                    )}
                </div> */}

                <div className='relative' onMouseEnter={() => handleMouseEnter('Resources')} onMouseLeave={handleMouseLeave}>
                    <div className=' py-2 px-4 flex gap-1 items-center cursor-pointer'>
                        Resources
                        <FaCaretDown size={16} />
                    </div>
                    {activeItem === 'Resources' && (
                        <div className='absolute top-full mt-[0px] z-50 bg-white text-black shadow-lg w-[200px] rounded-b-[20px] overflow-hidden'>
                            <Link to="/gallery" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Gallery</Link>
                            <Link to="/blog" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Blog</Link>
                        </div>
                    )}
                </div>

                <div className='relative' onMouseEnter={() => handleMouseEnter('Events')} onMouseLeave={handleMouseLeave}>
                    <div className=' py-2 px-4 flex gap-1 items-center cursor-pointer'>
                        Events
                        <FaCaretDown size={16} />
                    </div>
                    {activeItem === 'Events' && (
                        <div className='absolute top-full mt-[0px] z-50 bg-white text-black shadow-lg w-[200px] rounded-b-[20px] overflow-hidden'>
                            <Link to="/past-event" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Past</Link>
                            <Link to="/upcomming-event" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Upcoming</Link>
                            <Link to="/today-event" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Today's</Link>
                        </div>
                    )}
                </div>

                <div className='relative' onMouseEnter={() => handleMouseEnter('Get Involved')} onMouseLeave={handleMouseLeave}>
                    <div className=' py-2 px-4 flex gap-1 items-center cursor-pointer'>
                        Get Involved
                        <FaCaretDown size={16} />
                    </div>
                    {activeItem === 'Get Involved' && (
                        <div className='absolute top-full mt-[0px] z-50 bg-white text-black shadow-lg w-[200px] rounded-b-[20px] overflow-hidden'>
                            <Link to="/partners" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Partners</Link>
                            <Link to="/career" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Career</Link>
                            <Link to="/campaign" className='block py-2 px-4 hover:bg-primaryColor hover:text-white cursor-pointer'>Campaign</Link>
                        </div>
                    )}
                </div>

                <div >
                    <div className=' py-2 px-4 flex gap-1 items-center cursor-pointer'>
                        <Link to="/previous-projects">
                            Projects
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DesktopNavbar;
