import React, { useEffect, useState } from 'react';
import GetInvolvedHeadingCard from '../components/getInvolved/GetInvolvedHeadingCard';
import { BsPlus, BsDash } from 'react-icons/bs';
import axios from 'axios';

const Campaign = () => {
    const [activeOption, setActiveOption] = useState('');
    const [isExpanded, setIsExpanded] = useState(true);
    const [campaignData, setCampaignData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleOptionClick = (option) => {
        if (activeOption === option) {
            setIsExpanded(!isExpanded);
        } else {
            setActiveOption(option);
            setIsExpanded(true);
        }
    };

    const getAllCampaign = async () => {
        try {
            const config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/campaigns/categories`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const response = await axios.request(config);
            setCampaignData(response?.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            alert(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getAllCampaign();
    }, []);

    return (
        <div className='mb-20'>
            <GetInvolvedHeadingCard heading="Campaign" />
            <div className='lg:px-20 px-10 pt-5'>
                <p>
                    SSDO actively partners with civil society organizations to champion campaigns addressing pressing issues impacting underprivileged communities. Through our collaborative efforts, we empower marginalized groups to amplify their voices, advocating for meaningful change and social justice.
                </p>
                <div className="mt-4 grid grid-cols-1 gap-4">
                    {isLoading ? (
                        <div className="animate-pulse border rounded-lg">
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, ind) =>
                                    <div key={ind} className="h-8 bg-gray-300 rounded w-full mb-2"></div>
                                )
                            }
                        </div>
                    ) : (
                        campaignData && campaignData?.map((ele) =>
                            <div key={ele?._id}>
                                <div className="flex items-center border">
                                    <div
                                        className={`cursor-pointer py-2 px-2 flex items-center justify-between w-full transition duration-300 ${activeOption === `${ele?._id}` ? 'bg-primaryColor text-white' : 'hover:bg-gray-200'}`}
                                        onClick={() => handleOptionClick(ele?._id)}
                                    >
                                        <p>
                                            {ele?.category}
                                        </p>
                                        {isExpanded && activeOption === `${ele?._id}` ? <BsDash className="mr-2" /> : <BsPlus className="mr-2" />}
                                    </div>
                                </div>
                                {activeOption === `${ele?._id}` && (
                                    <div className={`pl-4 mt-2 transition duration-300 ${isExpanded ? 'opacity-100 max-h-full' : 'opacity-0 max-h-0 overflow-hidden'}`}>
                                        {
                                            ele?.campaigns.map((item) => <div key={item?._id}>
                                                <h1 className='text-lg font-medium'>{item?.name}</h1>
                                                <p className='mb-2 text-sm text-secondaryTextColor'>{item?.description}</p>
                                            </div>)
                                        }
                                    </div>
                                )}
                            </div>)
                    )}
                </div>
            </div>
        </div>
    );
}

export default Campaign;
