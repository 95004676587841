import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-semibold mb-4">Terms and Conditions</h1>
            <p>
                Welcome to the Shri Sathya Sai Development Organization (SSDO) website. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our website.
            </p>
            <h2 className="text-lg font-semibold mt-6 mb-2">1. Use of Website</h2>
            <p>
                The content of the pages of this website is for your general information and use only. It is subject to change without notice.
            </p>
            <h2 className="text-lg font-semibold mt-6 mb-2">2. Intellectual Property</h2>
            <p>
                All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
                Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
            </p>
            <h2 className="text-lg font-semibold mt-6 mb-2">3. Website Links</h2>
            <p>
                Our website may contain links to other websites which are not under the control of SSDO. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
            </p>
            <h2 className="text-lg font-semibold mt-6 mb-2">4. Disclaimer</h2>
            <p>
                The information contained in this website is for general information purposes only. The information is provided by SSDO and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
            </p>
            <h2 className="text-lg font-semibold mt-6 mb-2">5. Governing Law</h2>
            <p>
                Your use of this website and any dispute arising out of such use of the website is subject to the laws of India.
            </p>
            <h2 className="text-lg font-semibold mt-6 mb-2">6. Changes to Terms and Conditions</h2>
            <p>
                SSDO may revise these terms and conditions at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms and conditions.
            </p>
            <p>
                For any queries regarding these terms and conditions, please contact us at info@ssdo.org.
            </p>
        </div>
    );
}

export default TermsAndConditions;
