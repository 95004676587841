import React from 'react'
import image1 from '../../assets/Home/welcomeToCharity.png'

const WelcomeToCharity = () => {
    return (
        <div className='lg:px-20 py-10 px-10 md:grid md:grid-cols-2 flex flex-col-reverse md:gap-0 gap-4'>
            <div className='flex items-center'>
                <div>
                    <div className='flex items-center gap-2 mb-2'>
                        <p>Welcome to Charity</p>
                        <hr className='w-10'></hr>
                    </div>
                    <div className='text-[30px] font-serif mb-2'>
                        Let Us Come Together To Make a Difference
                    </div>
                    <p className='text-sm text-secondaryTextColor lg:w-[60%] mb-2'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                        nonumy tempor invidunt ut labore et dolore magna aliquyam
                        erat, sed diam voluptua. At vero eos et accusam et justo.
                    </p>
                    <div className='md:w-[70%] w-[100%] mt-2'>
                        <div className='flex items-center justify-between'>
                            <h1>Donations</h1>
                            <h1>75%</h1>
                        </div>
                        <div className='w-full h-2 bg-[#c7e7df]'>
                            <div className='h-full w-[75%] bg-[#209d80]'></div>
                        </div>
                    </div>
                    <div className='md:w-[70%] w-[100%] mt-2'>
                        <div className='flex items-center justify-between'>
                            <h1>Medical Help</h1>
                            <h1>90%</h1>
                        </div>
                        <div className='w-full h-2 bg-[#c7e7df]'>
                            <div className='h-full w-[90%] bg-[#209d80]'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-end'>
                <img src={image1} alt="welcome to charity image"
                    className='xl:w-[70%] lg:w-[80%] md:w-[90%] w-full'
                />
            </div>
        </div>
    )
}

export default WelcomeToCharity