import React, { useState } from 'react';
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

const OurTestimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const testimonials = [
        {
            name: "Cameron Williamson",
            position: "Founder",
            imageUrl: "https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.735520172.1710720000&semt=sph",
            testimonial: "Sea chub demoiselle whalefish zebra lionfish mud cat pelican eel. Minnow snoek icefish velvet-belly shark, California halibut round stingray northern sea robin. Southern grayling trout-perchSharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, Canthigaster rostrata.Southern grayling trout-perchSharksucker sea toad candiru rocket danio tilefish stingray deepwater stingray Sacramento splittail, Canthigaster rostrata."
        },
        {
            name: "Mirsat Williamson",
            position: "Founder",
            imageUrl: "https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.735520172.1710720000&semt=sph",
            testimonial: "Consequatur ut eum laudantium, ad vitae, ab veritatis quam fuga dolor praesentium minus reiciendis quo beatae inventore tenetur ex error consequuntur quis. Culpa quibusdam in qui perspiciatis dignissimos placeat eum. Temporibus nemo nulla, ipsam facere"
        },
        {
            name: "Saikh Williamson",
            position: "Founder",
            imageUrl: "https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.735520172.1710720000&semt=sph",
            testimonial: "voluptas in sequi sint officiis aliquid, quaerat sed alias autem corporis quia minus.Distinctio nulla esse impedit, a, soluta modi error laborum omnis consequatur atque doloribus facilis, repellendus voluptatum. illum, mollitia, sint minima porro saepe expedita delectus nisi."
        },

    ];

    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const handlePrev = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    return (
        <div className='lg:px-20 px-10 py-10 bg-[#35434a] text-white text-center'>
            <div className='flex items-center justify-center'>
                <div className='flex items-center gap-2'>
                    <hr className='w-[100px]' />
                    <h1>Our Testimonials</h1>
                    <hr className='w-[100px]' />
                </div>
            </div>
            <h1 className='text-3xl font-serif font-medium mt-4'>
                What People Say
            </h1>
            <div className='mt-4 flex'>
                <div className='w-[5%] flex items-center justify-end'>
                    <GrCaretPrevious className='cursor-pointer' onClick={handlePrev} />
                </div>
                <div className='w-[90%] flex items-center justify-center'>
                    <div className='w-[100%] h-[250px]'>
                        <div className='w-[70px] h-[70px] rounded-full  overflow-hidden m-auto'>
                            <img
                                className='w-full h-full rounded-full'
                                src={testimonials[currentIndex].imageUrl}
                                alt="avatar_img"
                            />
                        </div>
                        <h1 className='mt-2'>{testimonials[currentIndex].name}</h1>
                        <p className='text-[10px] font-serif mb-2'>{testimonials[currentIndex].position}</p>
                        <p className='text-sm tracking-wider w-[70%] m-auto overflow-scroll h-[100px]'>{testimonials[currentIndex].testimonial}</p>
                    </div>
                </div>
                <div className='w-[5%] flex items-center'>
                    <GrCaretNext className='cursor-pointer' onClick={handleNext} />
                </div>
            </div>
        </div>
    )
}

export default OurTestimonials;
