import React, { useEffect, useState } from 'react';
import EventHeadingCard from '../components/event/EventHeadingCard';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const TodayEvents = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [eventsData, setEventsData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getAllEvents();
    }, []);

    const getAllEvents = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/event/get-all-events`,
        };

        axios.request(config)
            .then((response) => {
                console.log(response?.data);
                const currentDate = new Date().toISOString().slice(0, 10);
                const todayEvents = response?.data.filter(event => event.eventDate.slice(0, 10) === currentDate);
                setEventsData(todayEvents);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text?.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    };

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const Skeleton = () => (
        <div className="animate-pulse bg-gray-200 rounded-lg shadow-xl border p-2 pb-6">
            <div className="w-full h-[300px] bg-gray-300"></div>
            <div className="text-center mt-2">
                <div className="w-3/4 h-6 mb-1 bg-gray-300"></div>
                <div className="w-1/2 h-6 mb-1 bg-gray-300"></div>
                <div className="w-4/5 h-6 mb-1 bg-gray-300"></div>
                <div className="w-3/4 h-6 mb-1 bg-gray-300"></div>
            </div>
        </div>
    );

    return (
        <div>
            <EventHeadingCard heading="Today Events" />
            {isLoading ? (
                <div className="lg:px-20 px-10 py-5 grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <Skeleton />
                    <Skeleton />
                </div>
            ) : eventsData.length > 0 ? (
                <div className="lg:px-20 px-10 py-5 grid lg:grid-cols-2 grid-cols-1 gap-4">
                    {eventsData.map((ele, index) => (
                        <div key={index} className="shadow-xl border p-2 pb-6 rounded-lg bg-[#E5F7F5]">
                            <div className="w-full">
                                <img className="border w-[100%] h-[300px]" src={ele.images[0]} alt="Past Event image" />
                            </div>
                            <div className="text-center mt-2">
                                <h1 className="font-bold">"{ele.title}"</h1>
                                <p className="text-sm text-secondaryTextColor mb-1">{formatDate(ele.eventDate)}</p>
                                <p className="text-sm text-secondaryTextColor">{truncateText(ele.descriptions[0], 150)}</p>
                                <button
                                    className="text-center mt-2 border-2 rounded border-primaryColor px-2 py-1 hover:bg-primaryColor hover:text-white"
                                    onClick={() => navigate(`/event/${ele._id}`)}
                                >Read More</button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-center bg-white text-gray-500 p-4">
                    <div className='border w-full text-center p-4 bg-gray-100 text-2xl'>No events for today</div>
                </div>
            )}
        </div>
    );
};

export default TodayEvents;
