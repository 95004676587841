import React, { useState } from 'react';
import bg_image from '../assets/paymentPage_bg.png';
import PaymentMethodModal from '../components/payment/PaymentMethodModal';

const PaymentPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        amount: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        message: ''
    });
    const [errors, setErrors] = useState({
        amount: '',
        name: '',
        email: '',
        phone: '',
        address: ''
    });

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let validationErrors = {};
        // Validation logic
        if (!formData.amount.trim()) {
            validationErrors.amount = 'Amount is required';
        }
        if (!formData.name.trim()) {
            validationErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }
        if (!formData.phone.trim()) {
            validationErrors.phone = 'Phone is required';
        } else if (!/^\d+$/.test(formData.phone)) {
            validationErrors.phone = 'Invalid phone number';
        }
        if (!formData.address.trim()) {
            validationErrors.address = 'Address is required';
        }
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            console.log('Form submitted:', formData);
            handleOpenModal()
        }
    };

    const isValid = () => {
        const requiredFields = ['amount', 'name', 'email', 'phone', 'address'];
        return requiredFields.every(field => formData[field].trim() !== '');
    };
    return (
        <div
            className="lg:px-20 px-10 grid lg:grid-cols-2 grid-cols-1 gap-6 justify-center items-center h-full py-10"
            style={{
                backgroundImage: `url(${bg_image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {isModalOpen && <PaymentMethodModal onClose={handleCloseModal} formData={formData} />}
            <div>
                <h1 className="text-2xl font-semibold mb-4">Donate Fund for SSDO</h1>
                <p className="mb-4">
                    The Reserve fund is considered to be the capital of the organization. The fund is generated and kept by us for the sustenance and existence of the organization.
                </p>
                <p className="mb-4">
                    The Reserve Fund is of paramount importance to the organization as it helps during times of distress. Only the interest/dividend earned from it is accumulated or utilized for general purposes.
                </p>
                <p className="mb-4">
                    It is required for the smooth sustenance of the organization. We are currently trying to build a reserve fund of 12 months of expenditure of the organization thereby ensuring smooth sustenance.
                </p>
                <p className="mb-4">
                    It will help reduce financial vulnerability and thereby help us achieve our goals. We request to contribute Rs. 5000 or above for the creation of Reserve Fund of SSDO Donation Cause.
                </p>
                <h2 className="text-lg font-semibold mb-2">HOW WILL THE FUNDS BE USED</h2>
                <ul className="list-disc pl-5 mb-4">
                    <li>Sustenance of the organization</li>
                    <li>Reduce financial vulnerability and thereby help us achieve our goals</li>
                </ul>
                <div className="text-sm">
                    <p>Contact Us:</p>
                    <p>info@ssdo.org</p>
                    <p>0123456789</p>
                </div>
                <div className="text-xs mt-4">
                    <p>Terms & Conditions:</p>
                    <p>You agree to share information entered on this page with SSDO (owner of this page) and our payment gateway provider, adhering to applicable laws.</p>
                </div>
            </div>

            <div className="w-full  p-6 bg-white shadow-md rounded-md">
                <h2 className="text-lg font-semibold mb-4">Payment Details</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col mb-1">
                        <label htmlFor="amount">Amount</label>
                        <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} className={`border ${errors.amount ? 'border-red-500' : 'border-gray-300'} px-3 py-2 rounded-md`} />
                        {errors.amount && <span className="text-red-500 text-[10px]">{errors.amount}</span>}
                    </div>
                    <div className="flex flex-col mb-1">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className={`border ${errors.name ? 'border-red-500' : 'border-gray-300'} px-3 py-2 rounded-md`} />
                        {errors.name && <span className="text-red-500 text-[10px]">{errors.name}</span>}
                    </div>
                    <div className="flex flex-col mb-1">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className={`border ${errors.email ? 'border-red-500' : 'border-gray-300'} px-3 py-2 rounded-md`} />
                        {errors.email && <span className="text-red-500 text-[10px]">{errors.email}</span>}
                    </div>
                    <div className="flex flex-col mb-1">
                        <label htmlFor="phone">Phone</label>
                        <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} className={`border ${errors.phone ? 'border-red-500' : 'border-gray-300'} px-3 py-2 rounded-md`} />
                        {errors.phone && <span className="text-red-500 text-[10px]">{errors.phone}</span>}
                    </div>
                    <div className="flex flex-col mb-1">
                        <label htmlFor="address">Address</label>
                        <textarea type="text" id="address" name="address" value={formData.address} onChange={handleChange} className={`border ${errors.address ? 'border-red-500' : 'border-gray-300'} px-3 py-2 rounded-md`} />
                        {errors.address && <span className="text-red-500 text-[10px]">{errors.address}</span>}
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="message">Message (Optional)</label>
                        <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="border border-gray-300 px-3 py-2 rounded-md" />
                    </div>
                    <button
                        type="submit"
                        className={`py-2 px-4 rounded-md ${isValid() ? 'bg-blue-500 hover:bg-blue-600 text-white font-semibold' : 'bg-gray-300 text-gray-400 cursor-not-allowed'}`}
                        disabled={!isValid()}
                    >
                        Choose your payment method
                    </button>
                </form>
            </div>

        </div>
    );
};

export default PaymentPage;
