import React, { useState, useRef, useEffect } from 'react';
import { RiArrowRightSFill } from 'react-icons/ri';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const OurServices = () => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const [showScrollbar, setShowScrollbar] = useState(false);
    const [hasNextContent, setHasNextContent] = useState(false);
    const [projects, setProjects] = useState([]);
    const scrollContainerRef = useRef(null);
    const cardWidth = 310;
    const navigate = useNavigate()

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const { scrollWidth, clientWidth } = scrollContainer;
            setShowScrollbar(scrollWidth > clientWidth);
            setHasNextContent(scrollWidth > clientWidth + scrollLeft);
        }
    }, [scrollContainerRef, scrollLeft]);

    const handleNext = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const { scrollWidth, clientWidth } = scrollContainer;
            const maxScrollLeft = scrollWidth - clientWidth;
            const nextScrollLeft = scrollLeft + cardWidth;
            if (nextScrollLeft <= maxScrollLeft) {
                setScrollLeft(nextScrollLeft);
                scrollContainer.scrollTo({
                    left: nextScrollLeft,
                    behavior: 'smooth',
                });
            }
            setHasNextContent(scrollWidth > clientWidth + nextScrollLeft);
        }
    };

    const handlePrev = () => {
        const nextScrollLeft = scrollLeft - cardWidth;
        if (nextScrollLeft >= 0) {
            setScrollLeft(nextScrollLeft);
            scrollContainerRef.current.scrollTo({
                left: nextScrollLeft,
                behavior: 'smooth',
            });
        } else {
            setScrollLeft(0);
            scrollContainerRef.current.scrollTo({
                left: 0,
                behavior: 'smooth',
            });
        }
        setHasNextContent(true);
    };

    const getProjectData = async () => {
        try {
            const config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/projects/get-all-projects`
            };
            const response = await axios.request(config);
            // console.log(response?.data)
            setProjects(response?.data)
        } catch (error) {
            console.error(error);
            console.log(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        getProjectData();
    }, [])

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        } else {
            return text;
        }
    };
    return (
        <div className="lg:px-20 px-10 py-10 bg-white">
            <div className="flex items-center gap-2 mb-4">
                <p>Our Services</p>
                <hr className="w-10"></hr>
            </div>
            <div className="md:flex flex-row items-center justify-between">
                <h1 className="md:text-4xl text-2xl font-serif mb-4 ">
                    Find the popular cause <br />
                    and donate to them
                </h1>
                <div className="flex items-center gap-2 mr-4 md:mb-0 mb-4">
                    <button
                        onClick={handlePrev}
                        disabled={scrollLeft === 0}
                        style={{ color: scrollLeft === 0 ? 'grey' : 'black' }}
                        className={`${scrollLeft === 0 ? "bg-gray-300 text-gray-400" : "bg-[#E5F7F5]"} border p-2 rounded-full  flex items-center justify-center`}
                    >
                        <IoIosArrowBack />
                    </button>
                    <button
                        onClick={handleNext}
                        disabled={!showScrollbar || !hasNextContent}
                        className={`${!showScrollbar || !hasNextContent ? "bg-gray-300 text-gray-400" : "bg-[#E5F7F5]"} border p-2 rounded-full flex items-center justify-center`}
                    >
                        <IoIosArrowForward />
                    </button>
                </div>
            </div>
            <div
                className="flex gap-4 overflow-x-scroll pb-4"
                style={{
                    scrollSnapType: 'x mandatory',
                    WebkitOverflowScrolling: 'touch',
                    overflowY: 'hidden',
                    overflowX: showScrollbar ? 'auto' : 'hidden',
                }}
                ref={scrollContainerRef}
            >
                {projects?.map((ele) => (
                    <div key={ele?._id} className="w-[305px] flex-shrink-0 bg-primaryBodyColor shadow-md rounded-lg overflow-hidden">
                        <div className="w-full h-[250px] overflow-hidden">
                            <img
                                src="https://images.unsplash.com/photo-1593113598332-cd288d649433?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                alt="images"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="p-4">
                            <p className="text-sm text-primaryColor">{ele?.location}</p>
                            <p className="mt-2 mb-2 font-medium">
                                {ele?.projectName}
                            </p>
                            <p className="text-sm text-secondaryTextColor tracking-wider">
                                {truncateText(ele?.description, 40)}
                            </p>
                            <div className="mt-2 flex items-center justify-end ">
                                <button
                                    onClick={() => navigate('/previous-projects')}
                                    className="cursor-pointer text-sm flex items-center">
                                    Read More
                                    <RiArrowRightSFill
                                        size={20}
                                        className="text-primaryColor mt-1"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurServices;
