import React from 'react'
import logo from '../assets/logo.svg'
import { AiOutlineYoutube } from "react-icons/ai";
import { CgInstagram } from "react-icons/cg";
import { FaXTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';

const quickLinks = [
    { title: "Who we are", path: "/about" },
    { title: "Our Team", path: "/about-our-teammates" },
    { title: "Terms & Conditions", path: "/term-and-conditions" },
    { title: "Privacy & Policy", path: "/privacy-and-policy" },
];

const Footer = () => {
    const navigate = useNavigate()
    const path = useLocation()

    return (
        <div className='border h-[auto] bg-white'>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 p-10'>
                <div>
                    <img className='w-[80px]' src={logo} alt="" />
                    <p className='mt-4 tracking-wide text-justify'>
                        SSDO is a non-profit working for rural upliftment, addressing social inequalities through development aid, training, and advocacy.
                    </p>
                    <div className='flex gap-2 mt-4'>
                        <div className='bg-[#E5F7F5] rounded-full p-2 text-primaryColor'>
                            <FaFacebookF size={30} />
                        </div>
                        <div className='bg-[#E5F7F5] rounded-full p-2 text-primaryColor'>
                            <CgInstagram size={30} />
                        </div>
                        <div className='bg-[#E5F7F5] rounded-full p-2 text-primaryColor'>
                            <FaLinkedinIn size={30} />
                        </div>
                        <div className='bg-[#E5F7F5] rounded-full p-2 text-primaryColor'>
                            <FaXTwitter size={30} />
                        </div>
                        <div className='bg-[#E5F7F5] rounded-full p-2 text-primaryColor'>
                            <AiOutlineYoutube size={30} />
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className='font-medium tracking-wider'>
                        QUICK LINK
                    </h1>
                    <hr className='w-16 mt-2 border-[1px]' style={{ borderColor: 'grey' }} />
                    <div className='mt-4 flex flex-col gap-2'>
                        {quickLinks.map((link, index) => (
                            <div key={index}
                                className="flex gap-2 bg-gray-100 cursor-pointer transition duration-[1s] ease-out hover:bg-gray-200"
                                onClick={() => navigate(`${link?.path}`)}
                                style={path?.pathname === link.path ? { backgroundColor: 'orange' } : {}}
                            >
                                <div className='w-[1%] bg-orange-500'></div>
                                <div className='w-[98%] py-1 tracking-wide'>
                                    {link.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <h1 className='font-medium tracking-wider'>
                        CONTACT INFO
                    </h1>
                    <hr className='w-16 mt-2 border-[1px]' style={{ borderColor: 'grey' }} />
                    <div className='mt-4'>
                        <p className='text-lg font-bold tracking-wide'>
                            SHRI SATHYASAI DEVELOPMENT ORGANIZATION. ODISHA.
                        </p>
                        <p className='mt-4 tracking-wide'>
                            Sri Sathya Sai development organisation Odisha, administrative office, Raghunathpur, Bhubaneswar, Odisha, Pin - 751024
                        </p>
                        <p className='mt-4 tracking-wide'>
                            Mobile No. - 7008275280, 9437087261
                        </p>
                    </div>
                </div>
            </div>
            <div className='p-2 flex md:flex-row tracking-wide flex-col  items-center justify-around bg-gray-300'>
                <p>© 2024 SS Social Development</p>
                <p>Designed by <span className='text-orange-500'>Web Bocket</span></p>
            </div>
        </div>
    )
}

export default Footer;
