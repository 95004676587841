import React from 'react';
import AboutHeadingCard from '../components/about/AboutHeadingCard';
import founder from '../assets/founder_image.jpeg';
import { IoIosFlower } from "react-icons/io";

const AboutChairPersons = () => {
    return (
        <div>
            <AboutHeadingCard heading="About Chair Persons" />
            <div className="lg:px-20 px-10 py-10">
                <div className="text-lg text-gray-800 leading-relaxed tracking-wider">
                    <div className="xl:flex lg:flex-row flex-col-reverse gap-8">
                        <div className='xl:w-[40%]'>
                            <img src={founder} alt="Chairperson" className="w-[100%] h-auto rounded-lg" />

                            <div className='text-center mt-4 tracking-wider'>
                                <span className='font-bold text-primaryTextColor'>
                                    KSHITISH CHANDRA MOHANTY
                                </span><br/>
                                <span className='font-semibold text-primaryHoverColor'>
                                    (FOUNDER CUM MEMBER SECRETARY)
                                </span>
                            </div>
                        </div>
                        <div className="text-lg text-gray-800 text-left xl:w-[60%] xl:mt-0 mt-4">
                            <h1 className='font-medium'>
                                Welcome to Shri Sathya Sai Development Organization!
                            </h1>

                            <p className="mb-4 mt-4 text-justify">
                                Shri Sathya Sai Development Organization (SSDO) is a non-governmental organization (NGO) dedicated to serving the community and fostering positive change. Founded by Kshitish Chandra Mohanty, who also serves as the Member Secretary, SSDO operates with the vision of creating a society where every individual has access to opportunities for growth, education, and well-being.
                            </p>
                            <p className="mb-4 text-justify">
                                <b className='text-primaryColor'>
                                    Our Mission:&nbsp;
                                </b>
                                At SSDO, our mission is to uplift the underprivileged, empower the marginalized, and promote sustainable development across various sectors. We believe in the principles of compassion, integrity, and service to humanity, striving to make a meaningful difference in the lives of those in need.
                            </p>
                            <p className="mb-4 text-justify">
                                <b className='text-primaryColor'>
                                    Location:&nbsp;
                                </b>
                                Our administrative office is located in the heart of Odisha, at Raghunathpur, Bhubaneswar. Situated amidst the vibrant culture and rich heritage of Odisha, our central office serves as a hub for our operations, enabling us to reach out to communities across the region. Our commitment to serving the people of Odisha extends to every corner of the state, from urban centers to remote villages.
                            </p>
                        </div>
                    </div>
                    <p>
                        <b className='text-primaryColor text-justify'>
                            Our Initiatives:&nbsp;
                        </b>
                        SSDO implements a range of initiatives aimed at addressing various social, economic, and environmental challenges. These initiatives include:
                    </p>
                    <div>
                        <div className='flex mb-2 lg:gap-0 gap-2 text-justify'>
                            <IoIosFlower size={15} className='text-primaryColor lg:w-[2%] w-[4%] mt-0.5' />
                            <p className='lg:w-[98%] w-[96%] text-sm'>
                                <b>Education and Skill Development: </b>
                                We believe in the power of education to transform lives. Through our educational programs and skill development initiatives, we empower individuals with the knowledge and skills they need to build a better future for themselves and their communities.
                            </p>
                        </div>
                        <div className='flex mb-2 lg:gap-0 gap-2 text-justify'>
                            <IoIosFlower size={15} className='text-primaryColor lg:w-[2%] w-[4%] mt-0.5' />
                            <p className='lg:w-[98%] w-[96%] text-sm'>
                                <b>Healthcare and Well-being: </b>
                                Access to healthcare is a fundamental right. SSDO works to improve healthcare infrastructure, promote preventive care, and provide medical assistance to those in need. Our efforts in healthcare extend to both rural and urban areas, focusing on ensuring equitable access to healthcare services for all.
                            </p>
                        </div>
                        <div className='flex mb-2 lg:gap-0 gap-2 text-justify'>
                            <IoIosFlower size={15} className='text-primaryColor lg:w-[2%] w-[4%] mt-0.5' />
                            <p className='lg:w-[98%] w-[96%] text-sm'>
                                <b>Livelihood Enhancement: </b>
                                Economic empowerment is essential for sustainable development. SSDO supports livelihood enhancement programs that create opportunities for income generation, entrepreneurship, and economic self-sufficiency. By promoting livelihoods, we aim to alleviate poverty and promote economic resilience among marginalized communities.
                            </p>
                        </div>
                        <div className='flex mb-2 lg:gap-0 gap-2 text-justify'>
                            <IoIosFlower size={15} className='text-primaryColor lg:w-[2%] w-[4%] mt-0.5' />
                            <p className='lg:w-[98%] w-[96%] text-sm'>
                                <b>Environmental Conservation: </b>
                                Protecting the environment is crucial for the well-being of present and future generations. SSDO undertakes various initiatives for environmental conservation, including tree plantation drives, waste management programs, and awareness campaigns on sustainable living practices.
                            </p>
                        </div>
                    </div>

                    <p className='mb-4 text-justify'>
                        <b className='text-primaryColor'>
                            Get Involved:&nbsp;
                        </b>
                        Join us in our mission to create a better world for all. Whether you're interested in volunteering, donating, or collaborating on projects, there are many ways to get involved with SSDO. Together, we can make a meaningful impact and bring about positive change in the lives of those who need it most.
                    </p>
                    <p className='mb-4 text-justify'>
                        <b className='text-primaryColor'>
                            Stay Connected:&nbsp;
                        </b>
                        Follow us on social media and subscribe to our newsletter to stay updated on our latest initiatives, events, and success stories. Your support and involvement are essential in helping us achieve our goals and create a brighter future for communities in need.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutChairPersons;
