import React, { useState } from 'react';
import axios from 'axios';

const ImageUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false); // New state for loading

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        try {
            setLoading(true); // Set loading to true when uploading starts

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('upload_preset', 'sssd_ngo');

            const response = await fetch(
                `https://api.cloudinary.com/v1_1/djmy1w2zl/image/upload`,
                {
                    method: 'POST',
                    body: formData,
                }
            );

            const data = await response.json();
            console.log(data);
            console.log(data?.secure_url)
            setImageUrl(data?.secure_url)

            await postImageFunc(data?.secure_url)

        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setLoading(false); // Reset loading state after upload completes or fails
        }
    };

    const postImageFunc = async (url) => {
        try {
            const config = {
                method: "POST",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/image/create`,
                data: { images: url }
            };

            const response = await axios.request(config);
            if (response?.data) {
                setSelectedFile(null);
                setImageUrl('')
                alert('image uploaded')
            }
            console.log(response?.data)
        } catch (error) {
            console.error(error);
            console.log(error?.response?.data?.message);
        }
    }

    return (
        <div className="max-w-md mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Image Upload</h2>
            <input type="file" onChange={handleFileChange} className="border border-gray-300 rounded-md px-4 py-2 mb-4" />
            <button onClick={handleUpload} className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600" disabled={loading}>
                {loading ? 'Uploading...' : 'Upload'}
            </button>
            {imageUrl && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-2">Uploaded Image:</h3>
                    <img src={imageUrl} alt="Uploaded" className="max-w-full h-auto" />
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
