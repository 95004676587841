import React, { useEffect } from 'react';
import ResourcesHeadingCard from '../components/resources/ResourcesHeadingCard';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Skeleton component for loading placeholders
const Skeleton = () => (
    <div className="rounded-lg shadow-lg bg-white h-auto p-4 flex md:md:ml-[80px] ml-[40px] border animate-pulse">
        <div className="flex items-center w-full">
            <div className="h-[180px] w-[250px] relative ml-[-80px] p-2 bg-gray-200 border shadow-lg rounded-lg" />
        </div>
        <div className="flex items-center justify-center ml-[-60px] w-[140%]">
            <div role="status">
                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
            </div>
        </div>
    </div>
);

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading indicator
    const navigate = useNavigate();

    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text?.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    };

    const getBlogData = async () => {
        try {
            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/blog-post/get-all-blogs`,
            };

            const response = await axios.request(config);
            console.log(response?.data);
            setBlogs(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            console.log(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getBlogData();
    }, []);

    function formatDate(dateString) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <div>
            <ResourcesHeadingCard heading="Our Blog" />
            <div className="lg:px-20 px-10 py-5 grid lg:grid-cols-2 grid-cols-1 gap-4">
                {loading ? ( // If loading is true, render skeleton loading
                    <>
                        <Skeleton />
                        <Skeleton />
                    </>
                ) : (
                    // Once data is fetched, render the blogs
                    blogs?.map((ele) => (
                        <div
                            key={ele?._id}
                            className="rounded-lg shadow-lg bg-white h-auto p-4 flex md:md:ml-[80px] ml-[40px] border"
                        >
                            <div className="flex items-center w-full">
                                <img
                                    src="https://www.cysd.org/wp-content/uploads/2024/02/32p13.png"
                                    alt="blog"
                                    className="h-[180px] w-[250px] relative ml-[-80px] p-2 bg-white border shadow-lg rounded-lg"
                                />
                            </div>
                            <div className="flex items-center justify-center ml-[-60px] w-[140%]">
                                <div className="p-4 pl-0">
                                    <h1 className="font-medium text-xl">{ele?.title}</h1>
                                    <p className="text-secondaryTextColor text-sm mt-1">
                                        {ele?.author} / {formatDate(ele?.dateOfPublish)}
                                    </p>
                                    <p className="text-sm mt-1">{truncateText(ele?.description[0], 40)}</p>
                                    <button
                                        onClick={() => navigate(`/blog/${ele?._id}`)}
                                        className="text-center mt-2 border-2 rounded border-primaryColor px-2 py-1 hover:bg-primaryColor hover:text-white"
                                    >
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Blog;
