import React, { useEffect, useState } from 'react';
import ResourcesHeadingCard from '../components/resources/ResourcesHeadingCard';
import axios from 'axios';

const Gallery = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const getAllImages = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/image/get-all`,
            headers: {},
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                // console.log(response?.data);
                setImages(response?.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAllImages();
    }, []);

    const openModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };

    const downloadImage = async () => {
        try {
            const response = await fetch(selectedImage);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg');
            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    return (
        <div>

            <ResourcesHeadingCard heading="Our Gallery" />

            <div className='lg:px-20 px-10 py-5'>
                <h1 className='font-bold text-2xl mb-4'>Photos</h1>

                {
                    isLoading ?
                        (
                            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4">
                                {
                                    [...Array(12)].map((_, index) => (
                                        <div
                                            key={index}
                                            className="border p-4 bg-white rounded relative overflow-hidden animate-pulse h-[250px] ">
                                            <div className='h-[100%] w-[100%] bg-gray-300 rounded-lg'></div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4'>
                                {
                                    images?.map((ele, ind) => (
                                        <div
                                            key={ele._id}
                                            className='border p-2 bg-white rounded relative overflow-hidden'
                                        >
                                            <img
                                                className='h-[200px] w-[100%] transition-all duration-300 transform hover:scale-105'
                                                src={ele?.images} alt="image" onClick={() => openModal(ele?.images)}
                                            />
                                            <div
                                                className='text-center font-bold absolute top-0 left-0 w-full h-full flex flex-col
                                                           justify-center items-center opacity-0 bg-black bg-opacity-50 hover:opacity-100 
                                                           transition-opacity duration-300'>
                                                <h1 className='text-white mt-2'>
                                                    Image-{ind + 1}
                                                </h1>
                                                <button
                                                    className='text-sm font-medium text-white border border-primaryColor px-4 py-1 mt-2 rounded
                                                             hover:bg-primaryColor hover:text-white transition duration-150 ease-in-out'
                                                    onClick={() => openModal(ele.images)}
                                                >
                                                    VIEW
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                }
            </div>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden p-2">
                        <img src={selectedImage} alt="image" className="w-full" style={{ maxHeight: '80vh' }} />

                        <div className="mt-2 flex justify-between p-4">
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                onClick={downloadImage}
                            >
                                Download
                            </button>
                            <button
                                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;
