import React, { useEffect, useState } from 'react';
import GetInvolvedHeadingCard from '../components/getInvolved/GetInvolvedHeadingCard';
import axios from 'axios';

const Career = () => {
    const [jobPositions, setJobPositions] = useState([])
    const [selectedJob, setSelectedJob] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    const openJobDetails = (jobId) => {
        setSelectedJob(jobId);
    };

    const closeJobDetails = () => {
        setSelectedJob(null);
    };

    useEffect(() => {
        getAllJobs();
    }, []);

    const getAllJobs = () => {
        setIsLoading(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/job/get-all-jobs`,
        };

        axios.request(config)
            .then((response) => {
                console.log(response?.data);
                setJobPositions(response?.data);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
    };

    return (
        <div>
            <GetInvolvedHeadingCard heading="Career" />
            <div className='lg:px-20 px-10 py-5'>
                <p>
                    Shri Sathya Sai Development Organization (SSSD) is a committed non-profit organization striving for positive change in Odisha. Since 1982, we've worked tirelessly to uplift tribal, rural, and urban poor communities, aiming to eradicate extreme poverty and ensure social justice and inclusion for all citizens.
                </p>
                <div>
                    <h1 className='text-2xl mt-4'>Current Vacancy</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4 mt-4">
                        {
                            isLoading ?
                                [1, 2, 3, 4].map((ele) =>
                                    <div className="animate-pulse bg-gray-200 rounded-lg shadow-xl border px-4 py-2">
                                        <div className="text-center mt-2">
                                            <div className="w-3/4 h-6 mb-1 bg-gray-300"></div>
                                            <div className="w-1/2 h-6 mb-1 bg-gray-300"></div>
                                            <div className="w-4/5 h-6 mb-1 bg-transparent"></div>
                                            <div className="w-3/4 h-6 mb-1 bg-gray-300"></div>
                                        </div>
                                    </div>
                                )
                                :
                                jobPositions.map((job) => (
                                    <div key={job?._id} className="border border-gray-300 rounded-lg overflow-hidden shadow-md">
                                        <div className="p-4">
                                            <h2 className="text-lg font-bold">{job?.title}</h2>
                                            <p className="text-sm text-gray-500 mt-2">{job?.location}</p>
                                            <button onClick={() => openJobDetails(job?._id)} className="mt-4 bg-primaryColor text-white px-4 py-2 rounded-md hover:bg-primaryColorLight">View Details</button>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>

                {selectedJob && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                        <div className="bg-white rounded-lg overflow-hidden max-w-xl w-full h-[500px] overflow-y-scroll">
                            <div className="p-4">
                                <h2 className="text-lg font-bold">{jobPositions.find(job => job._id === selectedJob).title}</h2>
                                <p className="text-sm text-gray-500 mt-2">{jobPositions.find(job => job._id === selectedJob).location}</p>
                                <hr className="my-4" />
                                <h3 className="text-md font-semibold">Responsibilities:</h3>
                                <ul className="list-disc list-inside pl-4 mt-2">
                                    {jobPositions.find(job => job._id === selectedJob).responsibilities.map((responsibility, index) => (
                                        <li key={index}>{responsibility}</li>
                                    ))}
                                </ul>
                                <h3 className="text-md font-semibold mt-4">Requirements:</h3>
                                <ul className="list-disc list-inside pl-4 mt-2">
                                    <li>{jobPositions.find(job => job._id === selectedJob).requirement.education}</li>
                                    <li>{jobPositions.find(job => job._id === selectedJob).requirement.experience}</li>
                                    <li>{jobPositions.find(job => job._id === selectedJob).requirement.language}</li>
                                    <li>{jobPositions.find(job => job._id === selectedJob).requirement.preference}</li>
                                </ul>
                                <h3 className="text-md font-semibold mt-4">Other Terms and Conditions:</h3>
                                <ul className="list-disc list-inside pl-4 mt-2">
                                    <li>Salary: {jobPositions.find(job => job._id === selectedJob).otherTerms.salary}</li>
                                    <li>{jobPositions.find(job => job._id === selectedJob).otherTerms.interview}</li>
                                    <li>{jobPositions.find(job => job._id === selectedJob).otherTerms.applicationDeadline}</li>
                                </ul>
                                <div className="mt-4">
                                    <p>Contact: {jobPositions.find(job => job._id === selectedJob).otherTerms.contact.name}</p>
                                    <p>Email: {jobPositions.find(job => job._id === selectedJob).otherTerms.contact.email}</p>
                                    <p>Phone: {jobPositions.find(job => job._id === selectedJob).otherTerms.contact.phone}</p>
                                </div>
                                <button onClick={closeJobDetails} className="mt-4 bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500">Close</button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Career;
